<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['currentStressLevel'])">
      <single-answer-question
        id="current-stress-level"
        title="label.currentStressLevel"
        :value="currentStressLevel"
        :options="$options.currentStressLevelOptions"
        dysfunction="oxidative-stress"
        @input="onFieldChange('currentStressLevel', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['sleepDeprived'])">
      <single-answer-question
        id="sleep-deprived"
        title="label.sleepDeprived"
        :value="sleepDeprived"
        :options="$options.yesNoResponseOptions"
        dysfunction="oxidative-stress"
        yes-no
        @input="onFieldChange('sleepDeprived', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { STRESS_LEVEL } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const CURRENT_STRESS_LEVEL_OPTIONS = [
  { value: STRESS_LEVEL.RELAXED, text: 'stressLevel.relaxed' },
  { value: STRESS_LEVEL.TIRED, text: 'stressLevel.tired' },
  { value: STRESS_LEVEL.STRESSED, text: 'stressLevel.stressed' },
  { value: STRESS_LEVEL.OVERLY_STRESSED, text: 'stressLevel.overlyStressed' }
];

export default {
  name: 'Lifestyle2Template',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  currentStressLevelOptions: CURRENT_STRESS_LEVEL_OPTIONS,
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    currentStressLevel: {
      type: String,
      required: true
    },
    sleepDeprived: {
      type: String,
      required: true
    }
  }
};
</script>
