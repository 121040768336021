<template>
  <lifestyle2-template
    :current-stress-level="currentStressLevel"
    :sleep-deprived="sleepDeprived"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import Lifestyle2Template from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/lifestyle-2/Lifestyle2Template';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('currentStressLevel'), requiredField('sleepDeprived')];

export default {
  name: 'Lifestyle2',
  components: { Lifestyle2Template },
  mixins: [makeStep(FIELDS)],
  watch: {
    currentStressLevel(newValue, oldValue) {
      if (!oldValue && this.sleepDeprived) {
        this.showNextStep();
      } else {
        this.scrollTo('#sleep-deprived');
      }
    },
    sleepDeprived(newValue, oldValue) {
      if (!oldValue && this.currentStressLevel) {
        this.showNextStep();
      }
    }
  }
};
</script>
